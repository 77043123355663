import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/Login.vue')
  },
  {
    path: '/home',
    name: 'Honme',
    component: () => import('@/views/home/Home.vue'),
    children: [
      {
        path: '/home/UserList',
        component: () => import('@/views/home/userList/UserList.vue')
      },
      {
        path: '/home/invitaionCode',
        component: () => import('@/views/home/invitaionCode/InvitaionCode.vue')
      },
      {
        path: '/home/domainList',
        component: () => import('@/views/home/domainList/DomainList.vue')
      },
      {
        path: '/home/appVersion',
        component: () => import('@/views/home/appVersion/AppVersion.vue')
      },
      {
        path: '/home/patchesUrl',
        component: () => import('@/views/home/patchesUrl/PatchesUrl.vue')
      },
      {
        path: '/home/taichiConfig',
        component: () => import('@/views/home/taichiConfig/TaichiConfig.vue')
      },
      {
        path: '/home/operationRecord',
        component: () => import('@/views/home/operationRecord/OperationRecord.vue')
      }
    ]
  },
  { path: '/', redirect: '/login' }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
